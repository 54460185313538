var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "mine page page-back",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "mine"
    }
  }, [_c('div', {
    staticClass: "mine-main page-bottom-bar"
  }, [_c('div', {
    staticClass: "balance"
  }, [_c('div', {
    staticClass: "balance__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Profit per hour")) + " "), _c('img', {
    staticClass: "balance__r",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
      "alt": ""
    }
  }), !isNaN(_vm.earnings.hour_earnings) ? _c('span', {
    staticClass: "balance__num"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.earnings.hour_earnings, 2)))]) : _vm._e(), _c('img', {
    staticClass: "balance__tip",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-tip.png`),
      "alt": ""
    },
    on: {
      "click": _vm.showTip
    }
  })]), _c('div', {
    staticClass: "balance-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "balance__num"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.mineBalance)))])])]), _c('div', {
    staticClass: "specials"
  }, [_c('div', {
    staticClass: "specials__header"
  }, [_c('div', {
    staticClass: "specials__title"
  }, [_c('span', [_vm._v("Emerald")]), _c('img', {
    staticClass: "specials__tip",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-tip.png`),
      "alt": ""
    },
    on: {
      "click": _vm.onAwardTip
    }
  })]), _c('div', {
    staticClass: "specials__header__right"
  }, [_vm.lastTime > 0 ? _c('div', {
    staticClass: "specials__time"
  }, [_vm._v(" " + _vm._s(_vm.lastTimeStr) + " ")]) : _vm._e(), _vm.specialsMineInfo.totalCoin > 0 ? _c('div', {
    staticClass: "specials__coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm._f("nFormatter")(_vm.specialsMineInfo.coin, 2)) + " /"), _c('span', {
    staticClass: "specials__total"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.specialsMineInfo.totalCoin, 2)))])]) : _vm._e()])]), _c('div', {
    staticClass: "specials__info"
  }, _vm._l(_vm.specialsMineInfo.list, function (item, index) {
    return _c('div', {
      staticClass: "specials__icon",
      class: item.gray ? 'gray-icon' : '',
      on: {
        "click": function ($event) {
          return _vm.openSpecialsDetail(item);
        }
      }
    }, [item.opened == 0 || item.gray ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/unknown.png`),
        "alt": ""
      }
    }) : _vm._e(), item && item.opened == 1 ? _c('img', {
      attrs: {
        "src": item.lucky_task_icon,
        "alt": ""
      }
    }) : _vm._e(), item && item.opened == 1 ? _c('div', {
      staticClass: "specials__name"
    }, [_vm._v(" " + _vm._s(item.lucky_task_short_name) + " ")]) : _c('div', {
      staticClass: "specials__name"
    }, [_vm._v(" Hint ")])]);
  }), 0)]), _c('div', {
    staticClass: "tab_main"
  }, [_c('ul', _vm._l(_vm.categoryList, function (item, index) {
    return _c('li', {
      staticClass: "tab_item",
      class: _vm.selectTab == item.id ? 'checked' : '',
      on: {
        "click": function ($event) {
          return _vm.click_tab(item.id);
        }
      }
    }, [_vm._v(_vm._s(_vm.$lang(item.name)))]);
  }), 0)]), _c('div', {
    staticClass: "boo"
  }, [_vm.taskList[_vm.selectTab] ? _c('div', {
    staticClass: "boo-list"
  }, _vm._l(_vm.taskList[_vm.selectTab], function (item, index) {
    return _c('div', {
      staticClass: "boo-item",
      class: item.pre_task_id != 'null' && !item.upgradable ? 'lock-item' : '',
      on: {
        "click": function ($event) {
          return _vm.onBoo(item);
        }
      }
    }, [item.pre_task_id != 'null' && !item.upgradable ? _c('div', {
      staticClass: "lock-icon",
      class: {
        'square-icon-lock': _vm.selectCategory.icon_type == 1
      }
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/lock.png`),
        "alt": ""
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "boo-icon",
      class: {
        'boo-icon-square': _vm.selectCategory.icon_type == 1
      }
    }, [_c('img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "boo-title"
    }, [_vm._v(" " + _vm._s(item.short_name) + " ")]), _c('div', {
      staticClass: "boo-info"
    }, [_c('div', {
      staticClass: "boo__cost",
      class: item.current_level == 0 ? 'gray-icon' : ''
    }, [_vm._v(" Profit per hour "), _c('img', {
      attrs: {
        "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
        "alt": ""
      }
    }), item.current_level == 0 ? _c('span', [_vm._v(" +" + _vm._s(_vm._f("nFormatter")(item.initial_earnings, 2)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm._f("nFormatter")(item.current_earnings, 2)) + " ")])]), _c('div', {
      staticClass: "boo__num"
    }, [_c('div', {
      staticClass: "boo__num__lvl"
    }, [_c('div', {
      staticClass: "boo__num_coin_text"
    }, [_vm._v(" lvl " + _vm._s(item.current_level) + " ")])]), item.upgradable ? _c('div', {
      staticClass: "boo__num__coin"
    }, [_c('img', {
      attrs: {
        "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
        "alt": ""
      }
    }), _vm._v(_vm._s(_vm._f("nFormatter")(item.upgrade_cost, 2)) + " ")]) : _c('div', {
      staticClass: "boo__upgrade_condition"
    }, [item.show_key ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/earn-key.png`),
        "alt": ""
      }
    }) : _vm._e(), _c('div', [_vm._v(_vm._s(item.dependency_tip))])])])])]);
  }), 0) : _vm._e()])]), _c('pop-mine-energy', {
    attrs: {
      "show": _vm.popEnergyShow,
      "type": _vm.selectCategory.icon_type,
      "taskInfo": _vm.taskInfo
    },
    on: {
      "update:show": function ($event) {
        _vm.popEnergyShow = $event;
      }
    }
  }), _c('pop-tip-energy', {
    attrs: {
      "show": _vm.popEngrayTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popEngrayTipShow = $event;
      }
    }
  }), _c('pop-award-tip', {
    attrs: {
      "show": _vm.popAwardTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAwardTipShow = $event;
      }
    }
  }), _c('pop-award', {
    attrs: {
      "show": _vm.popAwardShow,
      "award": _vm.awardCoin
    },
    on: {
      "update:show": function ($event) {
        _vm.popAwardShow = $event;
      }
    }
  }), _c('pop-special-detail', {
    attrs: {
      "show": _vm.popSpecialsDetailShow,
      "row": _vm.specialsItem
    },
    on: {
      "update:show": function ($event) {
        _vm.popSpecialsDetailShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };