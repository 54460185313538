var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "frens page page-back",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "frens-main page-bottom-bar"
  }, [_c('div', {
    staticClass: "total"
  }, [_c('div', {
    staticClass: "total__title"
  }, [_vm._v(_vm._s(_vm.$lang("Total Rewards")))]), _c('div', {
    staticClass: "total__num"
  }, [_vm._v("000" + _vm._s(_vm._f("toThousands")(_vm.userData.total_reward)))]), _c('img', {
    staticClass: "total__img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/img-reward.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invite"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Invite frens to get bonuses")))]), _c('div', {
    staticClass: "invite-main"
  }, [_c('div', {
    staticClass: "invite-item"
  }, [_c('div', {
    staticClass: "invite__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invite_info"
  }, [_c('div', {
    staticClass: "invite__label"
  }, [_vm._v(_vm._s(_vm.$lang(`Invite Fren`)))]), _c('div', {
    staticClass: "invite__brief"
  }, [_c('i', [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.setting.reward.normal, 2)))]), _vm._v(_vm._s(_vm.$lang(` for you and fren`)))])])]), _c('div', {
    staticClass: "invite-item"
  }, [_c('div', {
    staticClass: "invite__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/frens-3.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invite_info"
  }, [_c('div', {
    staticClass: "invite__label"
  }, [_vm._v(_vm._s(_vm.$lang(`Fren with Telegram Preminum`)))]), _c('div', {
    staticClass: "invite__brief"
  }, [_c('i', [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.setting.reward.premium, 2)))]), _vm._v(" " + _vm._s(_vm.$lang(`for you and fren`)))])])])])]), _vm.extraInvite.length > 0 ? _c('div', {
    staticClass: "invite extra"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Invite extra rewards")))]), _c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll"
    }],
    staticClass: "invite-main"
  }, _vm._l(_vm.extraInvite, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "invite-item",
      on: {
        "click": function ($event) {
          return _vm.onExtra(item);
        }
      }
    }, [_c('div', {
      staticClass: "invite__img"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/squad-icon-3.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "invite_info"
    }, [_c('div', {
      staticClass: "invite__label"
    }, [_vm._v(_vm._s(item.count > 1 ? _vm.$lang(`Invite ${item.count} friends to get`) : _vm.$lang(`Invite ${item.count} friend to get`)))]), _c('div', {
      staticClass: "invite__brief"
    }, [_c('img', {
      attrs: {
        "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
        "alt": ""
      }
    }), _vm._v(" "), _c('i', [_vm._v(_vm._s(_vm._f("toThousands")(item.reward)))])]), _c('div', {
      staticClass: "invite__process"
    }, [_c('div', {
      staticClass: "process"
    }, [_c('div', {
      staticClass: "bar",
      style: {
        width: `${(_vm.userData.extra_invite_count >= item.count ? item.count : _vm.userData.extra_invite_count) * 100 / item.count}%`
      }
    })]), _vm._v(" " + _vm._s(_vm.userData.extra_invite_count >= item.count ? item.count : _vm.userData.extra_invite_count) + " "), _c('span', [_vm._v("/ " + _vm._s(item.count))])])]), _c('div', {
      staticClass: "op"
    }, [item.has_claim ? [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })] : [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })]], 2)]);
  }), 0)]) : _vm._e(), _vm.firendList.length > 0 ? _c('div', {
    staticClass: "fs"
  }, [_c('div', {
    staticClass: "fs__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Frens List")) + " "), _c('span', [_vm._v("(" + _vm._s(_vm.userData.extra_invite_count) + ")")])]), _c('div', {
    staticClass: "fs-list"
  }, _vm._l(_vm.firendList, function (item, index) {
    return _c('div', {
      staticClass: "fs-item"
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.coin)) + " ")])])], 1);
  }), 0)]) : _vm._e()]), _c('pop-share', {
    attrs: {
      "show": _vm.popShareShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popShareShow = $event;
      }
    }
  }), _c('pop-extra-invite', {
    attrs: {
      "show": _vm.popExtraInviteShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popExtraInviteShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "btn-send",
    on: {
      "click": _vm.onBtnSend
    }
  }, [_vm._v(_vm._s(_vm.$lang("Send To Invite")))]), _c('div', {
    staticClass: "bottom-bar"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };