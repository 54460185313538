import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const mineVuex = createNamespacedHelpers("mine");
const assetsVuex = createNamespacedHelpers("assets");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "wallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData", 'extraInvite']),
    ...mineVuex.mapState(["hourEearnings", 'totalHourEearnings']),
    ...assetsVuex.mapState(["assetsChainsList", "assetsList"])
  },
  data() {
    return {
      loadConfig,
      list: []
    };
  },
  async mounted() {
    this.formatList();
    await this.getAssetsChains();
    await this.getAssetsList();
    this.formatList();
  },
  beforeRouteLeave(to, from, next) {
    this.$nextTick(() => {
      next();
    });
  },
  methods: {
    ...assetsVuex.mapActions(["getAssetsChains", "getAssetsList"]),
    ...assetsVuex.mapMutations(["setChainsInfo", "setAssetsInfo"]),
    onWithdraw(citem, item) {
      this.setChainsInfo(citem);
      this.setAssetsInfo(item);
      this.$router.push(`/withdraw?cid=${citem.id}&id=${item.id}`);
    },
    onProolUrl(url) {
      this.WebApp.openLink(url);
    },
    formatList() {
      this.list = this.assetsChainsList;
      this.list.map(item => {
        item.assets = [];
        this.assetsList.map(sitem => {
          if (sitem.chain == item.id) {
            item.assets.push(sitem);
          }
        });
        return item;
      });
    }
  }
};