import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "mine",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      tabIndex: 0,
      avatar: {
        current: '',
        desc: '',
        list: [{
          name: 'Astronaut',
          desc: 'The Astronaut is curious, brave, and visionary, ready to explore new frontiers.'
        }, {
          name: 'Cowboy',
          desc: 'The Cowboy is adventurous, rugged, and daring, exuding the spirit of the Wild West.'
        }, {
          name: 'Cyborg-Racer',
          desc: 'The Cyborg Racer is speedy, daring, and technologically savvy, mastering circuits both digital and racetrack.'
        }, {
          name: 'Elf-Warrior',
          desc: 'The Elf Warrior is agile, vigilant, and graceful, safeguarding nature’s secrets.'
        }, {
          name: 'Future-Warrior',
          desc: 'The Future Warrior showcases an innovative, formidable, and strategic demeanor.'
        }, {
          name: 'Mechanical-Engineer',
          desc: 'The Mechanical Engineer is resourceful, intelligent, and meticulous, solving complex problems.'
        }, {
          name: 'Pirate',
          desc: 'The Pirate is mischievous, bold, and cunning, sailing the digital seas.'
        }, {
          name: 'Samurai',
          desc: 'The Samurai embodies honor, discipline, and stoicism.'
        }, {
          name: 'SuperHero',
          desc: 'The Superhero is heroic, valiant, and charismatic, protecting the digital world.'
        }, {
          name: 'Wizard',
          desc: 'The Wizard is wise, mystical, and enigmatic, casting spells in the realm of code.'
        }]
      },
      bg: {
        current: '',
        list: ['bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7']
      }
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$nextTick(() => {
      next();
    });
  },
  created() {
    this.getCurrentSkin();
  },
  async mounted() {},
  watch: {},
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    getCurrentSkin() {
      this.avatar.current = this.userData.skin_avatar;
      this.bg.current = this.userData.skin_bg;
      for (const item of this.avatar.list) {
        if (item.name === this.avatar.current) {
          this.avatar.desc = item.desc;
          break;
        }
      }
    },
    async onUse() {
      if (!this.avatar.current) {
        this.$toasted.error("Please select avatar");
        return;
      }
      if (!this.bg.current) {
        this.$toasted.error("Please select background");
        return;
      }
      let rs = await this.$http.post("/my/skin/update", {
        avatar: this.avatar.current,
        bg: this.bg.current
      });
      if (rs.code !== 0) {
        this.$toasted.error(rs.message);
        return;
      }
      this.$toasted.success(rs.message);
      this.setUserData(rs.data);
    }
  }
};