import { createNamespacedHelpers } from "vuex";
import PopSpinResult from "@components/popSpinResult.vue";
import Vue from "vue";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "spine",
  components: {
    PopSpinResult
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      prizeList: [{
        txt: "500K",
        title: "points",
        img: require("@/assets/img/coin.png")
      }, {
        txt: "500K",
        title: "points",
        img: require("@/assets/img/coin.png")
      }, {
        txt: "50",
        title: "USDT",
        img: require("@/assets/img/usdt.png")
      }, {
        txt: "1",
        title: "FIN",
        img: require("@/assets/img/nft.png")
      }, {
        txt: "500K",
        title: "points",
        img: require("@/assets/img/coin.png")
      }, {
        txt: "500K",
        title: "points",
        img: require("@/assets/img/coin.png")
      }, {
        txt: "1",
        title: "NFT",
        img: require("@/assets/img/nft.png")
      }, {
        txt: "50",
        title: "USDT",
        img: require("@/assets/img/usdt.png")
      }],
      valve: false,
      // 防止连续点击
      recordList: [{
        type: 1,
        title: 500,
        sub: "Points"
      }, {
        type: 2,
        title: 500,
        sub: "Points"
      }, {
        type: 3,
        title: 500,
        sub: "Points"
      }, {
        type: 1,
        title: 500,
        sub: "Points"
      }, {
        type: 2,
        title: 500,
        sub: "Points"
      }, {
        type: 3,
        title: 500,
        sub: "Points"
      }, {
        type: 1,
        title: 500,
        sub: "Points"
      }, {
        type: 2,
        title: 500,
        sub: "Points"
      }, {
        type: 3,
        title: 500,
        sub: "Points"
      }, {
        type: 1,
        title: 500,
        sub: "Points"
      }, {
        type: 2,
        title: 500,
        sub: "Points"
      }, {
        type: 3,
        title: 500,
        sub: "Points"
      }, {
        type: 1,
        title: 500,
        sub: "Points"
      }, {
        type: 2,
        title: 500,
        sub: "Points"
      }, {
        type: 3,
        title: 500,
        sub: "Points"
      }, {
        type: 1,
        title: 500,
        sub: "Points"
      }, {
        type: 2,
        title: 500,
        sub: "Points"
      }],
      showPopResult: false
    };
  },
  beforeRouteLeave(to, from, next) {},
  async mounted() {
    Vue.prototype.$http.post("/wheel/list").then(data => {
      console.log(data);
    }).catch(err => {
      console.log(err);
    });
  },
  watch: {},
  methods: {
    showResult() {
      this.showPopResult = true;
    },
    // 开始转动
    go() {
      if (this.valve) {
        return;
      }
      this.valve = true;

      //:todo
      Vue.prototype.$http.post("/wheel/play").then(data => {
        console.log(data);
      }).catch(err => {
        console.log(err);
      });

      // 模拟随机数，这里可以请求后台获取中将信息
      const index = Math.floor(Math.random() * this.prizeList.length);

      // 转动转盘
      this.$refs.bigWheel.rotate(index);
    },
    // 转盘转完事件
    over(prizeInfo) {
      console.log(`恭喜获得${prizeInfo.txt}`);
      this.valve = false;
    }
  }
};