var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "top-conter"
  }, [_c('div', {
    staticClass: "top-nav"
  }, [_c('div', {
    staticClass: "nav-avatar",
    on: {
      "click": function ($event) {
        return _vm.onSkip('user');
      }
    }
  }, [_c('img', {
    staticClass: "nav-avatar-img",
    attrs: {
      "src": require(`@images/${_vm.theme}/avatar/${_vm.userData.skin_avatar || 'Default'}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "nav-info"
  }, [_c('div', {
    staticClass: "nav-info-title"
  }, [_vm._v("Profit per hour")]), _c('div', {
    staticClass: "nav-info-bottom"
  }, [_c('img', {
    staticClass: "nav-info-icon",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-star.png`),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "nav-info-text"
  }, [_vm._v("+2,005")])])]), _c('div', {
    staticClass: "nav-right"
  }, [_c('div', {
    staticClass: "nav-item",
    on: {
      "click": _vm.onBindWallet
    }
  }, [_c('svg-icon', {
    staticStyle: {
      "width": "16px",
      "height": "15px"
    },
    attrs: {
      "name": "icon-wallet"
    }
  })], 1), _c('div', {
    staticClass: "nav-item has-dropdown"
  }, [_c('svg-icon', {
    staticStyle: {
      "width": "16px",
      "height": "17px"
    },
    attrs: {
      "name": "icon-menu"
    }
  }), _c('div', {
    staticClass: "nav-dropdown"
  }, [_c('div', {
    staticClass: "nav-dropdown-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip(_vm.userData.user_group_id <= 0 ? 'join' : 'squad', _vm.userData.user_group_id);
      }
    }
  }, [_c('div', {
    staticClass: "nav-dropdown-icon"
  }, [_c('svg-icon', {
    staticStyle: {
      "width": "16px",
      "height": "14px"
    },
    attrs: {
      "name": "icon-users"
    }
  })], 1), _c('span', {
    staticClass: "nav-dropdown-text"
  }, [_vm._v(_vm._s(_vm.$lang("Squad")))])]), _c('div', {
    staticClass: "nav-dropdown-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('rank');
      }
    }
  }, [_c('div', {
    staticClass: "nav-dropdown-icon"
  }, [_c('svg-icon', {
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "name": "icon-rank"
    }
  })], 1), _c('span', {
    staticClass: "nav-dropdown-text"
  }, [_vm._v(_vm._s(_vm.$lang("Ranking")))])])])], 1)])]), _c('pop-bind-wallet', {
    attrs: {
      "show": _vm.popBindWalletShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popBindWalletShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };