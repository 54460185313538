var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "user page page-back",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "user"
    }
  }, [_c('div', {
    staticClass: "top"
  }, [_c('m-topbar')], 1), _c('div', {
    staticClass: "user-main"
  }, [_c('div', {
    staticClass: "user-show"
  }, [_c('div', {
    staticClass: "show-img"
  }, [_vm.avatar.current ? _c('img', {
    staticClass: "show-avatar",
    attrs: {
      "src": require(`@images/${_vm.theme}/avatar/${_vm.avatar.current}.png`)
    }
  }) : _vm._e(), _vm.bg.current ? _c('img', {
    staticClass: "show-bg",
    attrs: {
      "src": require(`@images/${_vm.theme}/bg/${_vm.bg.current}_sm.png`)
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "show-ifo"
  }, [_c('div', {
    staticClass: "info-title"
  }, [_vm._v(_vm._s(_vm.avatar.current))]), _c('div', {
    staticClass: "info-desc"
  }, [_vm._v(_vm._s(_vm.avatar.desc))]), _c('div', {
    staticClass: "btn",
    class: {
      'btn-disabled': !_vm.bg.current || !_vm.avatar.current
    },
    on: {
      "click": _vm.onUse
    }
  }, [_vm._v("Use")])])]), _c('div', {
    staticClass: "user-tabs"
  }, [_c('div', {
    staticClass: "tab-hd"
  }, [_c('div', {
    staticClass: "tab-item",
    class: {
      'tab-active': _vm.tabIndex === 0
    },
    on: {
      "click": function ($event) {
        _vm.tabIndex = 0;
      }
    }
  }, [_vm._v("Avatar")]), _c('div', {
    staticClass: "tab-item",
    class: {
      'tab-active': _vm.tabIndex === 1
    },
    on: {
      "click": function ($event) {
        _vm.tabIndex = 1;
      }
    }
  }, [_vm._v("Background")])]), _c('div', {
    staticClass: "tab-bd"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabIndex === 0,
      expression: "tabIndex === 0"
    }],
    staticClass: "list-conter"
  }, [_vm._l(_vm.avatar.list, function (item, i) {
    return [_c('div', {
      staticClass: "tab-item",
      class: {
        'tab-active': _vm.avatar.current === item.name
      },
      on: {
        "click": function ($event) {
          _vm.avatar.current = item.name, _vm.avatar.desc = item.desc;
        }
      }
    }, [_c('img', {
      staticClass: "item-icon",
      attrs: {
        "src": require(`@images_v2/${_vm.theme}/icon-checked.png`),
        "alt": ""
      }
    }), _c('img', {
      staticClass: "item-avatar",
      attrs: {
        "src": require(`@images/${_vm.theme}/avatar/${item.name}.png`),
        "alt": ""
      }
    })])];
  })], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabIndex === 1,
      expression: "tabIndex === 1"
    }],
    staticClass: "list-conter"
  }, [_vm._l(_vm.bg.list, function (item, i) {
    return [_c('div', {
      staticClass: "tab-item",
      class: {
        'tab-active': _vm.bg.current === item
      },
      on: {
        "click": function ($event) {
          _vm.bg.current = item;
        }
      }
    }, [_c('img', {
      staticClass: "item-icon",
      attrs: {
        "src": require(`@images_v2/${_vm.theme}/icon-checked.png`),
        "alt": ""
      }
    }), _c('img', {
      staticClass: "item-bg",
      attrs: {
        "src": require(`@images/${_vm.theme}/bg/${item}_sm.png`),
        "alt": ""
      }
    })])];
  })], 2)])])])]), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };