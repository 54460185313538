var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "bigWheel",
    staticClass: "__big-wheel"
  }, [_c('div', {
    staticClass: "wrap",
    style: {
      transform: _vm.rotateAngle,
      transition: _vm.rotateTransition
    }
  }, [_c('canvas', {
    ref: "canvas",
    attrs: {
      "id": "canvas"
    }
  }, [_vm._v("浏览器版本过低")]), _c('div', {
    staticClass: "prize-wrap"
  }, _vm._l(_vm.prizeList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      style: _vm._calcRotateAngle(index)
    }, [_c('div', {
      staticClass: "item-wrapper"
    }, [_c('div', {
      staticClass: "prize-name"
    }, [_vm._v(_vm._s(item.txt))]), _c('div', {
      staticClass: "prize-name-sub"
    }, [_vm._v(_vm._s(item.title))]), _c('img', {
      staticClass: "prize-img",
      attrs: {
        "src": item.img
      }
    })])]);
  }), 0)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };