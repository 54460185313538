var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "90%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Watch the video to get rewards")))]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("Watch the video, find the Lucky Code and pasteit here")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.luckyCodePsw,
      expression: "luckyCodePsw"
    }],
    attrs: {
      "type": "text",
      "placeholder": `Enter Lucky Code`
    },
    domProps: {
      "value": _vm.luckyCodePsw
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.luckyCodePsw = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "btn",
    class: {
      'btn-disabled': !_vm.luckyCodePsw
    },
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))]), _c('div', {
    staticClass: "info-item"
  }, [_c('img', {
    staticClass: "item-img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/img-item_def.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item-cont"
  }, [_c('div', {
    staticClass: "item-top"
  }, [_vm._v("Trade Crypto Without Waiting!")]), _c('div', {
    staticClass: "item-bottom"
  }, [_c('img', {
    staticClass: "item-bottom-img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "item-r"
  }, [_vm._v("9,957,918")]), _c('img', {
    staticClass: "item-bottom-img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-key_sm.png`),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "item-m"
  }, [_vm._v("1")])])])]), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v(_vm._s(_vm.$lang("Watch")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };