import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const dailyViex = createNamespacedHelpers("daily");
export default {
  name: "sign",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...dailyViex.mapState(["steps", "tomorrowDistance"])
  },
  data() {
    return {
      lastTime: 0,
      lastTimer: null,
      lastTimeStr: '',
      popSignShow: false,
      todayItem: {}
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.lastTimer) {
      clearInterval(this.lastTimer);
    }
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    //if(this.steps.length == 0){
    await this.getSteps([1]);
    //}
    this.lastTime = this.tomorrowDistance;
    this.countDown();
  },
  methods: {
    ...dailyViex.mapActions(["getSteps", "updatedDaily"]),
    sign_in(item) {
      if (!item.completed && item.today) {
        this.todayItem = item;
        this.popSignShow = true;
        // this.$loading.show()
        // this.updatedDaily([1,()=>{
        //   this.$loading.hide()
        // },()=>{
        //   this.$loading.hide()
        // }])
      }
    },
    countDown() {
      clearInterval(this.lastTimer);
      this.lastTimer = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
          this.lastTimeStr = this.formatter.formatHour(this.lastTime);
        } else {
          clearInterval(this.lastTimer);
        }
      }, 1000);
    }
  }
};