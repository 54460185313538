import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true
    };
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {
        if (this.userData.id) this.isLoading = true;
      },
      immediate: true,
      deep: true
    },
    "$route.path": {
      handler(newPath, oldPath) {
        console.log("$route.path", newPath, oldPath);
        if (newPath === "/") {
          this.WebApp.BackButton.isVisible && this.WebApp.BackButton.hide();
          this.WebApp.isClosingConfirmationEnabled = true;
          return;
        }
        this.WebApp.BackButton.show();
      },
      deep: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["getSetting", "loadTaskCount"]),
    ...userVuex.mapActions(["login", "getUserData", "powerPlus"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    initStatistics() {
      document.title = this.setting.coin;
      if (this.setting.statistics.length > 0) {
        this.setting.statistics.map(item => {
          if (item.type == "plausible" && item.value) {
            const pScript = document.createElement('script');
            pScript.src = `https://plausible.io/js/script.js`;
            pScript.defer = true;
            pScript.setAttribute("data-domain", item.value);
            document.head.appendChild(pScript);
          }
          if (item.type == "google" && item.value) {
            const gaScript = document.createElement('script');
            gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${item.value}`;
            gaScript.async = true;
            gaScript.onload = () => {
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', item.value);
            };
            document.head.appendChild(gaScript);
          }
        });
      }
    },
    onlineRefresh() {
      if (this.userData) this.$http.post("/user/online/refresh", {});
      setTimeout(() => {
        this.onlineRefresh();
      }, 180 * 1000);
    }
  },
  async mounted() {
    console.log("this.WebApp.initData", this.WebApp);
    await this.getSetting();
    // const initData = 'user=%7B%22id%22%3A1989068375%2C%22first_name%22%3A%22Yong%22%2C%22last_name%22%3A%22Goo%22%2C%22username%22%3A%22Yanggooo%22%2C%22language_code%22%3A%22zh-hans%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2Fpk0golXyHnrjBnT3wyU-m_J-nQafmLVP-Wdu-Vz954Y.svg%22%7D&chat_instance=9004354033141933577&chat_type=group&start_param=ref_BTTy7G&auth_date=1734590884&signature=qtYmBJIJXo5K_FW-s42hanIgqprfhNUXbifj7sO-b4u4t3E_9M0HNmwqRK0C-ZhkeYBUFhcObx-y1fFH9GitBQ&hash=cc30e7070e3491f3803a19834873f5d0992aa4ced57288bf8a53405c895ad492';
    // await this.login([initData,this.WebApp.initDataUnsafe.start_param || '']);
    await this.login([this.WebApp.initData, this.WebApp.initDataUnsafe.start_param || '']);
    this.powerPlus();
    this.initStatistics();
    setTimeout(() => {
      console.log("refresh");
      this.onlineRefresh();
    }, 180 * 1000);
    this.WebApp.BackButton.onClick(() => {
      this.$router.go(-1);
    });
    this.WebApp.isClosingConfirmationEnabled = true;
    // this.WebApp.disableVerticalSwipes();
  }
};