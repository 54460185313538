var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "mine page page-back",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "mine"
    }
  }, [_c('div', {
    staticClass: "spin-content bg-spin"
  }, [_c('div', {
    staticClass: "big-wheel-box-wrapper"
  }, [_c('div', {
    staticClass: "big-wheel-box"
  }, [_c('big-wheel', {
    ref: "bigWheel",
    attrs: {
      "prizeList": _vm.prizeList
    },
    on: {
      "over": _vm.over
    }
  }), _c('img', {
    staticClass: "btn-go",
    attrs: {
      "src": require('@/assets/img/go.png')
    },
    on: {
      "click": _vm.go
    }
  })], 1)]), _c('div', {
    staticClass: "spin-center"
  }, [_c('div', {
    staticClass: "spin-center-top"
  }, [_c('div', {
    staticClass: "spin-center-item"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/img/coin.png')
    }
  }), _c('div', [_vm._v(" 0.02 ")])]), _c('div', {
    staticClass: "spin-center-item"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/img/usdt.png')
    }
  }), _c('div', [_vm._v(" 500M ")])])]), _c('div', {
    staticClass: "spin-center-button",
    on: {
      "click": _vm.showResult
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/img/coin.png')
    }
  }), _c('div', [_vm._v(" 500k ")])])]), _c('div', {
    staticClass: "spin-record"
  }, [_c('div', {
    staticClass: "spin-record-title"
  }, [_vm._v(" Records ")]), _c('div', {
    staticClass: "spin-record-list"
  }, _vm._l(_vm.recordList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "spin-record-list-item"
    }, [item.type == 1 ? _c('img', {
      attrs: {
        "src": require('@/assets/img/coin.png')
      }
    }) : _vm._e(), item.type == 2 ? _c('img', {
      attrs: {
        "src": require('@/assets/img/usdt.png')
      }
    }) : _vm._e(), item.type == 3 ? _c('img', {
      attrs: {
        "src": require('@/assets/img/nft.png')
      }
    }) : _vm._e(), _c('div', {
      staticClass: "spin-record-list-item-right"
    }, [_vm._v(" " + _vm._s(item.title) + " " + _vm._s(item.sub) + " ")])]);
  }), 0)]), _c('PopSpinResult', {
    attrs: {
      "visible": _vm.showPopResult
    }
  })], 1)]), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };