var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "reflectionIndex",
    class: [_vm.isMobile && 'wap', _vm.theme]
  }, [[_c('div', {
    staticClass: "bg",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/bg/${_vm.userData.skin_bg || 'bg-1'}.png`)})`
    }
  }), _vm.isShowTurboScreen ? _c('div', {
    staticClass: "turboBg"
  }, _vm._l(2, function (_) {
    return _c('span', {
      style: {
        backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-small-coin.png`)})`
      }
    });
  }), 0) : _vm._e()], _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('m-topbar'), _c('div', {
    staticClass: "top-entry"
  }, [_c('div', {
    staticClass: "entry-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('sign');
      }
    }
  }, [_c('div', {
    staticClass: "entry-top"
  }, [_c('img', {
    staticClass: "entry-icon",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-entry-login.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "entry-text"
  }, [_vm._v("Daily Login")])]), _c('div', {
    staticClass: "entry-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('youtube', 204);
      }
    }
  }, [_c('div', {
    staticClass: "entry-top"
  }, [_c('img', {
    staticClass: "entry-icon entry-icon_lucky",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-entry-lucky.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "entry-text"
  }, [_vm._v("Lucky Code")])]), _c('div', {
    staticClass: "entry-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('mine');
      }
    }
  }, [_c('div', {
    staticClass: "entry-top"
  }, [_c('img', {
    staticClass: "entry-icon entry-icon_bounty",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-entry-bounty.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "entry-text"
  }, [_vm._v("Daily Bounty")])]), _c('div', {
    staticClass: "entry-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('frens');
      }
    }
  }, [_c('div', {
    staticClass: "entry-top"
  }, [_c('img', {
    staticClass: "entry-icon entry-icon_invite",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-entry-invite.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "entry-text"
  }, [_vm._v("Invites")])])])], 1), _c('div', {
    staticClass: "center"
  }, [_c('div', {
    ref: "totalRef",
    staticClass: "center-total",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticClass: "total-icon",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "total__value",
    class: !_vm.isMobile && 'pc'
  }, [!_vm.isIos ? [_c('CountUp', {
    attrs: {
      "num": _vm.userData.coin
    }
  })] : [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))]], 2)]), _c('div', {
    ref: "coinRef",
    staticClass: "center-main",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleClick($event);
      },
      "click": function ($event) {
        !_vm.isMobile && _vm.handleClick($event);
      }
    }
  }, [_c('div', {
    staticClass: "main-r"
  }, [_c('img', {
    staticClass: "main-r-icon",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-r.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "main-warrior"
  }, [_c('img', {
    ref: "warriorIconRef",
    staticClass: "warrior-icon",
    attrs: {
      "src": require(`@images/${_vm.theme}/avatar/${_vm.userData.skin_avatar || 'Future-Warrior'}.png`),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "center-progress"
  }, [_c('div', {
    staticClass: "progress-main"
  }, [_c('div', {
    staticClass: "progress__text"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/sd.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.userData.power)), _c('i', [_vm._v("/" + _vm._s(_vm.userData.power_max))])])]), _c('div', {
    staticClass: "bar"
  }, [_c('div', {
    staticClass: "bar-main",
    style: {
      left: -100 + Number(_vm.progressBar) + '%'
    }
  })])]), _c('div', {
    staticClass: "btn-boosts",
    on: {
      "click": function ($event) {
        return _vm.onSkip('boost');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/btn-icon-boost.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Boosts")) + " ")])])]), _c('div', {
    ref: "coinListRef",
    staticClass: "coin_list"
  })]), _c('div', {
    staticClass: "bottom-bar",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('m-menu')], 1), [_c('div', {
    ref: "blockRef",
    staticClass: "block",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleClick($event);
      },
      "click": function ($event) {
        !_vm.isMobile && _vm.handleClick($event);
      }
    }
  }, _vm._l(_vm.blockAnimList, function (item, index) {
    return _c('span', {
      key: index,
      class: item.bool && 'active',
      style: {
        left: item.x,
        top: item.y
      }
    }, [_vm._v("+" + _vm._s(_vm.turbo_temp_times <= 0 ? _vm.userData.multi_tap : _vm.userData.multi_tap * _vm.turbo_temp_times))]);
  }), 0)], _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowTurboIcon,
      expression: "isShowTurboIcon"
    }],
    ref: "turboRef",
    staticClass: "turbo",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      },
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/turbo.png`),
      "alt": ""
    }
  })]), _c('pop-robot', {
    attrs: {
      "show": _vm.popRobotShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRobotShow = $event;
      }
    }
  }), _c('pop-adv-tip', {
    attrs: {
      "show": _vm.popAdvTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAdvTipShow = $event;
      }
    }
  }), _c('pop-login-reward', {
    attrs: {
      "show": _vm.popLoginRewardShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popLoginRewardShow = $event;
      }
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };