import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "mTopbar",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      popBindWalletShow: false
    };
  },
  methods: {
    onSkip(page, id) {
      if (page === "squad") {
        this.$router.push(`/${page}?id=${id}`);
        return;
      }
      this.$router.push(`/${page}`);
    },
    onBindWallet() {
      // this.$nextTick(() => {
      //   this.tonWallet.openOrCloseTonModal(true);
      // });
      // if(this.userData.bind_wallet) return
      this.popBindWalletShow = true;
    }
  }
};