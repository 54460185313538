var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "luckyCode page page-back"
  }, [_c('div', {
    staticClass: "page-hd"
  }, [_c('img', {
    staticClass: "hd-img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-entry-lucky.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "hd-title"
  }, [_vm._v("luck code")]), _vm._m(0)]), _c('div', {
    staticClass: "page-bd"
  }, [_c('div', {
    staticClass: "list-title"
  }, [_vm._v("Emerald")]), _c('div', {
    staticClass: "list-conter"
  }, [_c('div', {
    staticClass: "list-item",
    on: {
      "click": function ($event) {
        _vm.popEntryLuckyCodeShow = true;
      }
    }
  }, [_c('img', {
    staticClass: "item-img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/img-item_def.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item-cont"
  }, [_c('div', {
    staticClass: "item-top"
  }, [_vm._v("Trade Crypto Without Waiting!")]), _c('div', {
    staticClass: "item-bottom"
  }, [_c('img', {
    staticClass: "item-bottom-img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "item-r"
  }, [_vm._v("9,957,918")]), _c('img', {
    staticClass: "item-bottom-img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-key_sm.png`),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "item-m"
  }, [_vm._v("5.14M")])])]), _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "list-item"
    });
  })], 2)])]), _c('div', {
    staticClass: "bottom-bar"
  }, [_c('m-menu')], 1), _c('pop-entry-lucky-code', {
    attrs: {
      "show": _vm.popEntryLuckyCodeShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popEntryLuckyCodeShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "hd-desc"
  }, [_vm._v("Watch this video from beginning to end at normal speed"), _c('br'), _vm._v(" and enterthe lucky code to get a reward")]);
}];
export { render, staticRenderFns };