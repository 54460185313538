var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c('div', {
    staticClass: "pop-dialog"
  }, [_c('div', {
    staticClass: "shadow"
  }), _c('div', {
    staticClass: "dialog-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Congratulations ! ")]), _vm.spinResult.currency.toLowerCase() == 'points' || _vm.spinResult.currency.toLowerCase() == 'usdt' ? _c('div', {
    staticClass: "spin-result-type"
  }, [_c('img', {
    attrs: {
      "src": _vm.spinResult.icon,
      "alt": ""
    }
  }), _c('div', {
    staticClass: "spin-result-type-sub"
  }, [_vm._v(_vm._s(_vm.spinResult.title))]), _c('div', {
    staticClass: "spin-result-type-title"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.spinResult.amount, 2, true, true)))])]) : _vm._e(), _vm.spinResult.currency.toLowerCase() == 'nft' ? _c('div', {
    staticClass: "spin-result-type"
  }, [_c('img', {
    staticClass: "spin-result-type-3-image",
    attrs: {
      "src": _vm.spinResult.icon,
      "alt": ""
    }
  }), _c('div', {
    staticClass: "spin-result-type-sub"
  }, [_vm._v("1 Special Limited NFT")]), _c('div', {
    staticClass: "spin-result-type-title"
  }, [_vm._v("amount 1")]), _c('div', {
    staticClass: "spin-result-type-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.bindWalletAddress,
      expression: "bindWalletAddress"
    }],
    attrs: {
      "type": "text",
      "placeholder": "enter wallet adress"
    },
    domProps: {
      "value": _vm.bindWalletAddress
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.bindWalletAddress = $event.target.value;
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "confirm-button",
    class: {
      'confirm-button-disable': !_vm.confirmEnable
    },
    on: {
      "click": _vm.confirmFn
    }
  }, [_vm._v(" Confirm ")])])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };