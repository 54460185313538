import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "okxWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...taskVuex.mapState(["taskList"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      isOk: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.isOk = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    this.taskId = this.$route.query.id;
    if (this.taskList.length == 0) await this.getTaskList();
    this.taskList.forEach(element => {
      if (element.id == this.taskId) {
        if (element.completed) {
          this.isOk = true;
        }
      }
    });
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...taskVuex.mapMutations(["setTaskComplete"]),
    ...taskVuex.mapActions(["getTaskList", "doTask"]),
    ...userVuex.mapMutations(['setUserData']),
    jumpTo() {
      this.WebApp.openLink("https://www.ouxyi.link/ul/wVd8l3");
    },
    openW() {
      if (!this.isOk) {
        this.tonWallet.openOrCloseSignTonModal("okxTonWallet");
        this.tonWallet.ton.onStatusChange(walletAndwalletInfo => {
          console.log("bind ok");
          if (walletAndwalletInfo) {
            let address = this.tonWallet.tranAddress(walletAndwalletInfo.account.address);
            this.checkCode(address);
            this.tonWallet.ton.disconnect();
          }
        });
      }
    },
    checkCode(value) {
      this.doTask([this.taskId, "", value, data => {
        this.code = '';
        this.setTaskComplete({
          id: this.taskId
        });
        this.isOk = true;
        let newUserData = {
          ...this.userData,
          ...(data && data.userInfo ? data.userInfo : data)
        };
        this.setUserData(newUserData);
        this.loadTaskCount();
      }, rs => {
        this.error = rs && rs.message;
      }]);
    }
  }
};