var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "90%",
      "height": "auto"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.itemData && _vm.itemData.id ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Watch The Video To Get Rewards')) + " ")]), _c('div', {
    staticClass: "number-box"
  }, [_c('div', {
    staticClass: "number__title"
  }, [_vm._v(_vm._s(_vm.$lang('Watch the video, find the Lucky Code and pasteit here')))]), _c('div', {
    staticClass: "number__items"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.verificationCodes,
      expression: "verificationCodes"
    }],
    staticClass: "verification-input",
    attrs: {
      "placeholder": `Enter Lucky Code`
    },
    domProps: {
      "value": _vm.verificationCodes
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.verificationCodes = $event.target.value;
      }
    }
  })])]), _vm.showTip ? _c('div', {
    staticClass: "tip"
  }, [_vm._v("Looks like you didn't watch the video. You need watch the whole video from start to the end in normal speed")]) : _vm._e(), _c('div', {
    staticClass: "btn-submit",
    class: !_vm.isWatch && 'dis',
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Submit")) + " ")]), _c('div', {
    staticClass: "invite-item",
    class: _vm.itemData.completed == 1 && 'active',
    on: {
      "click": _vm.onClick
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_vm.itemData.icon ? [_c('img', {
    attrs: {
      "src": _vm.itemData.icon
    }
  })] : [_vm.itemData.type == 'discord' ? _c('svg-icon', {
    attrs: {
      "name": "icon-discord"
    }
  }) : _vm._e(), _vm.itemData.type == 'telegram' || _vm.itemData.type == 'channel' ? _c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  }) : _vm._e(), _vm.itemData.type == 'twitter' ? _c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  }) : _vm._e()]], 2), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.itemData.title))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    staticClass: "invite-img__num",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.itemData.reward)))]), _vm.itemData.reward_stock > 0 ? [_c('img', {
    staticClass: "invite-img__keys",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-key_sm.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.itemData.reward_stock))])] : _vm._e()], 2)]), _c('div', {
    staticClass: "invite-arrow",
    class: _vm.itemData.completed == -1 && 'refresh'
  }, [_vm.itemData.completed == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.itemData.completed == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm.itemData.completed == -1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-refresh"
    }
  })] : _vm._e()], 2)]), _c('div', {
    staticClass: "btns",
    on: {
      "click": _vm.onClick
    }
  }, [_c('div', {
    staticClass: "btn"
  }, [_vm._v(_vm._s(_vm.$lang('Watch')))])])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };