import { createNamespacedHelpers } from "vuex";
import Vue from "vue";
const userVuex = createNamespacedHelpers("user");
const walletVuex = createNamespacedHelpers("wallet");
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    spinResult: {
      type: Object,
      default: () => {
        return {
          amount: 500,
          currency: "",
          icon: "",
          id: 5,
          title: ""
        };
      }
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"])
  },
  watch: {
    spinResult: {
      handler(newValue, oldValue) {
        console.log("change reuslt: ", newValue, oldValue);
        if (newValue && newValue?.currency?.toLowerCase() == "nft") {
          this.confirmEnable = false;
        } else {
          this.confirmEnable = true;
        }
      },
      deep: true,
      immediate: true
    },
    bindWalletAddress: {
      handler(newValue, oldValue) {
        if (newValue && newValue.length > 10) {
          this.confirmEnable = true;
        } else {
          this.confirmEnable = false;
        }
      }
    }
  },
  data() {
    return {
      confirmEnable: false,
      bindWalletAddress: ""
    };
  },
  methods: {
    cancelFn() {
      this.$emit("cancel");
    },
    confirmFn() {
      console.log("click confitm");
      if (!this.confirmEnable) {
        return;
      }
      if (this.spinResult.currency.toLowerCase() == "nft") {
        Vue.prototype.$http.post("/lucky/lottery/claim", {
          id: this.spinResult.id,
          address: this.bindWalletAddress
        }).then(data => {
          console.log(data);
          this.$emit("confirm");
        }).catch(err => {
          console.log(err);
        });
      } else {
        this.$emit("confirm");
      }
    }
  }
};