import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
import vailcode from "@utils/errcode";
import PopEntryLuckyCode from "../components/popEntryLuckyCode.vue";
export default {
  name: "luckyCode",
  components: {
    PopEntryLuckyCode
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      list: [],
      popEntryLuckyCodeShow: false
    };
  },
  methods: {}
};