var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "vue-lucky-wheel",
    style: _vm.style
  }, [_c('div', {
    staticClass: "vue-lucky-wheel-main",
    style: _vm.mainStyle
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "vue-lucky-wheel-button"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/img/go.png')
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };