var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "youtube page page-back",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "youtube-main page-bottom-bar"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "top__icon"
  }, [_c('img', {
    staticClass: "top-img",
    attrs: {
      "src": require(`@images_v2/${_vm.theme}/icon-entry-lucky.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "top__num"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Luck Code')) + " ")]), _c('div', {
    staticClass: "top__tip"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Watch this video from beginning to end at normal speed and enterthe lucky code to get a reward')) + " ")])]), _c('div', {
    staticClass: "ts"
  }, [_vm.subTaskList.length > 0 ? _c('div', {
    staticClass: "ts-list"
  }, _vm._l(_vm.subTaskList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "invite-item",
      class: item.completed == 1 && 'active',
      on: {
        "click": function ($event) {
          return _vm.onClick(item);
        }
      }
    }, [_c('div', {
      staticClass: "invite-icon"
    }, [item.icon ? [_c('img', {
      attrs: {
        "src": item.icon
      }
    })] : [item.type == 'discord' ? _c('svg-icon', {
      attrs: {
        "name": "icon-discord"
      }
    }) : _vm._e(), item.type == 'telegram' || item.type == 'channel' ? _c('svg-icon', {
      attrs: {
        "name": "icon-telegram"
      }
    }) : _vm._e(), item.type == 'twitter' ? _c('svg-icon', {
      attrs: {
        "name": "icon-lfg"
      }
    }) : _vm._e()]], 2), _c('div', {
      staticClass: "invite-info"
    }, [_c('div', {
      staticClass: "invite__title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "invite__num"
    }, [_c('img', {
      staticClass: "invite-img__num",
      attrs: {
        "src": require(`@images_v2/${_vm.theme}/icon-r_sm.png`),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(item.reward)))]), item.reward_stock > 0 ? [_c('img', {
      staticClass: "invite-img__keys",
      attrs: {
        "src": require(`@images_v2/${_vm.theme}/icon-key_sm.png`),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(item.reward_stock))])] : _vm._e()], 2)]), _c('div', {
      staticClass: "invite-arrow",
      class: item.completed == -1 && 'refresh'
    }, [item.completed == 0 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })] : item.completed == 1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })] : item.completed == -1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-refresh"
      }
    })] : _vm._e()], 2)]);
  }), 0) : _vm._e()])])]), _c('div', {
    staticClass: "bottom-bar"
  }, [_c('m-menu')], 1), _c('pop-youtube', {
    attrs: {
      "show": _vm.popYoutubeShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popYoutubeShow = $event;
      }
    }
  }), _c('pop-reward-result', {
    attrs: {
      "show": _vm.isPopRewardResultShow,
      "select-row": _vm.selectRow,
      "showBtn": false,
      "title": _vm.$lang('Congratulations on getting')
    },
    on: {
      "update:show": function ($event) {
        _vm.isPopRewardResultShow = $event;
      },
      "accept": _vm.onAccept
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };