import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
import { nFormatter } from "@utils/index";
import luckyCode from "../view/luckyCode.vue";
export default {
  name: "popEntryLuckyCode",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      luckyCodePsw: '',
      shareData: "",
      loadConfig
    };
  },
  watch: {
    "$parent.popShareShow": {
      handler(bool) {
        if (bool) {
          this.shareData = this.shareTxt || `%F0%9F%8E%81%20+${nFormatter(this.setting.reward.normal, 2)}%20${this.setting.coin}%20as%20a%20first-time%20gift%0A%F0%9F%8E%81%F0%9F%8E%81%F0%9F%8E%81%20+${nFormatter(this.setting.reward.premium, 2)}%20${this.setting.coin}%20if%20you%20have%20Telegram%20Premium%20`;
        }
      },
      deep: true,
      immediate: true
    }
  },
  props: {
    shareTxt: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    onShare() {
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=${this.shareData}&url=https://t.me/${this.setting.botname}/${this.setting.app}?startapp=ref_${this.userData.ref_code}`);
    }
  }
};